<template>
  <div class="c-app">
    <div style="margin: auto; text-align: center;">
    BIENVENIDO A COLIBRI BOUTIQUE
    <BR />
    <BR />
    <a href="/#/login">INICIAR SESION</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
